import React from 'react';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {layoutOptions} from "../components/Content/RichText";
import { Helmet } from "react-helmet";
import Img from 'gatsby-image';
import Layout from "../components/layout";
import moment from 'moment';
import style from "../components/News/News.module.css";

const BlogPostContent = ({data, location, ...props}) => {
    const { slug, publishDate, title, copy, image, topic } = data.contentfulBlogPost;
    const content = data.contentfulBlogPost;
    const richText = documentToReactComponents(content.content.json, layoutOptions);
    const titleBlog = `${title}`;
    const url = `https://www.upplan.sg/blog/${slug}`
    const description = copy.copy

    return (
        <Layout location={location}>
        <Helmet>
            <title>{titleBlog}</title>
            <meta name="image" content={`https:${image.resize.src}`} />
            <meta name="url" content={url} />
            <meta name="description" content={description} />

            <meta property="og:title" content={titleBlog} />
            <meta property="og:image" content={`https:${image.resize.src}`} />
            <meta property="og:url" content={url} />
            <meta property="og:description" content={description} />

            <meta name="twitter:title" content={titleBlog} />
            <meta name="twitter:image" content={`https:${image.resize.src}`} />
            <meta name="twitter:url" content={url} />
            <meta name="twitter:description" content={description} />
            <link rel="canonical" href={`https://www.upplan.sg/blog/${slug}`} />
        </Helmet>

        <section className="uk-section uk-section-small">
            <div className={`uk-container uk-container-small`}>
                <h1 className={`${style.postHeader} uk-margin-remove-adjacent uk-margin-remove-top`}>
                    {title}
                </h1>
                <small className="uk-text-muted">Posted on {moment(publishDate).format('MMM D, YYYY')}</small>
            </div>
        </section>
        <section className="uk-section uk-section-small">
            { image ? (
                <div className="uk-container uk-section uk-padding-small">
                    <div className="uk-position-relative">
                        <Img
                            className={style.blogheroImage}
                            fluid={image.fluid}
                            alt={image.title}
                            objectFit="cover"
                            objectPosition="50% 50%"
                             />
                    </div>
                </div>
            ) : null}
        </section>

        <div className={`${style.postContent}`}>
            {richText}
        </div>
        </Layout>
    );
};

export default BlogPostContent;
export const newsPostQuery = graphql`
    query newsPostQuery($slug: String) {
        contentfulBlogPost(slug: {
            eq: $slug
        }) {
            slug
            title
            copy {
                copy
                childMarkdownRemark {
                    html
                }
            }
            publishDate
            content {
                json
            }
            image {
                resize(width: 1600, quality: 100) {
                    src
                }
                fluid(maxWidth: 1600, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp
                }
            }
        }
    }`
