import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router";
import SEO from "../components/seo";

import {Navigation} from "./Navigation"
import {Footer} from "./Footer"

const newPath = ['up-adviser', 'up_adviser', 'upadviser', 'up-advisor', 'up_advisor', 'upadvisor'];
const showForm = ['thank-you', 'success', 'thanks']

const Layout = ({ children, ...props }) => {
    const { pathname } = useLocation()
    const adviser = newPath.some(path => pathname.includes(path))
    const displayForm = !showForm.some(path => pathname.includes(path))

  useEffect(
        () => {
            try {
                const UIkit = require("uikit/dist/js/uikit");
                const Icons = require("uikit/dist/js/uikit-icons");
                UIkit.use(Icons);
            } catch (e) {
                console.error("UIKit ERROR", e);
            }
        }, []
    )

  return (
    <>
        <Helmet
            script={[{
                type: 'text/javascript',
                innerHTML: '!function(e,t,a,n){e="//tags.tiqcdn.com/utag/bettertradeoff/main/prod/utag.js",t=document,a="script",n=t.createElement(a),n.src=e,n.type="text/java"+a,n.async=!0,e=t.getElementsByTagName(a)[0],e.parentNode.insertBefore(n,e)}();'
            }]}
        />
        <Navigation adviser={adviser} />
        <main>
        {children}
        </main>
        <Footer adviser={adviser} displayForm={displayForm} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
